import * as React from "react"
import type { ConsumerBillType, ConsumerInfoType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import type { DateRange } from "react-day-picker"

import { getBillPerConsumer, getConsumer } from "@/lib/api/billing"
import { DataTable } from "@/components/data-table"
import { DateRangePicker } from "@/components/date-range-picker"
import { Header } from "@/components/header"
import { consumerBillColumns } from "@/components/table/consumer-bill-columns"

export const Route = createFileRoute("/_home/manage-users/view/$id")({
  loader: async ({ params: { id } }) => {
    return { id }
  },
  component: ViewConsumerPage,
})

function ViewConsumerPage() {
  const { id } = Route.useLoaderData()
  const [date, setDate] = React.useState<DateRange>({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date(),
  })

  const { data: consumerInfo } = useQuery<ConsumerInfoType>({
    queryKey: ["consumerInfo", id],
    queryFn: async () => {
      const consumer = await getConsumer(Number(id))
      return { ...consumer, id }
    },
  })

  const {
    data: consumerBilling,
    isLoading,
    isError,
  } = useQuery<ConsumerBillType[]>({
    queryKey: ["consumerBill", date, id],
    queryFn: async () => {
      const from = date.from ?? new Date()
      const to = date.to ?? new Date()
      return await getBillPerConsumer(
        Number(id),
        from.toISOString(),
        to.toISOString(),
      )
    },
  })

  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "/manage-users",
            label: "Manage Consumers",
          },
        ]}
      >
        {`Billing History (${consumerInfo?.full_name})` || "N/A"}
      </Header>
      <DataTable
        columns={consumerBillColumns}
        data={consumerBilling ?? []}
        search={{
          field: "name",
          label: "name",
        }}
        isLoading={isLoading}
        isError={isError}
      >
        <div className="flex w-auto justify-end md:w-full">
          <DateRangePicker date={date} setDate={setDate} />
        </div>
      </DataTable>
    </main>
  )
}
