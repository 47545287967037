import * as React from "react"
import type { ConsumerBillType, EditConsumerBillingType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { editConsumerBilling } from "@/lib/api/billing"
import { useAwsS3 } from "@/hooks/use-aws-s3"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "@/components/ui/credenza"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

interface DataProps {
  data: ConsumerBillType
}

export function ConsumerBillOperations({ data }: DataProps) {
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false)
  const [showBillModal, setShowBillModal] = React.useState<boolean>(false)
  const { downloadFileFromS3 } = useAwsS3()

  return (
    <div>
      <DropdownMenu open={showDropDown} onOpenChange={setShowDropDown}>
        <DropdownMenuTrigger>
          <div className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted">
            <Icons.ellipsis className="size-4" />
            <span className="sr-only">Open</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onSelect={() => {
              setShowBillModal(true)
              setShowDropDown(false)
            }}
          >
            <Icons.edit className="mr-2 size-4" />
            Update Bill
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={async () => {
              const url = await downloadFileFromS3(data.file_path)

              if (url) {
                window.open(url, "_blank")
              } else {
                console.error("Failed to get download URL.")
              }
            }}
          >
            <Icons.fileScan className="mr-2 size-4" />
            View Bill
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={async () => {
              const url = await downloadFileFromS3(data.file_path)
              if (url) {
                window.open(url, "_blank")
              } else {
                console.error("Failed to get download URL.")
              }
            }}
          >
            <Icons.download className="mr-2 size-4" />
            Download Bill
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <EditBillModal
        data={data}
        showModal={showBillModal}
        setShowModal={setShowBillModal}
      />
    </div>
  )
}

function EditBillModal({
  data,
  showModal,
  setShowModal,
}: {
  data: ConsumerBillType
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const formSchema = z.object({
    new_consumption: z.coerce
      .number()
      .min(1, "Current consumption is required"),
    adjustment_reason: z
      .string()
      .min(1, "Account number is required")
      .max(255, "Adjustment reason is too long"),
    adjustment_notes: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_consumption: data.units || 0,
      adjustment_reason: "",
      adjustment_notes: "",
    },
  })

  const mutation = useMutation({
    mutationFn: async (values: z.infer<typeof formSchema>) => {
      await editConsumerBilling(data.billing_id, values)
    },
    onSuccess: () => {
      toast({
        description: "Bill updated successfully.",
      })
      queryClient.invalidateQueries({ queryKey: ["consumerBill"] })
      setShowModal(false)
    },
    onError: (error: unknown) => {
      toast({
        title: "Something went wrong!",
        description: `${error}`,
        variant: "destructive",
      })
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutation.mutate(values)
  }

  return (
    <>
      <Credenza open={showModal} onOpenChange={setShowModal}>
        <CredenzaContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <CredenzaHeader>
                <CredenzaTitle>Bill Adjustment</CredenzaTitle>
                <CredenzaDescription>
                  Update a bill for the selected consumer
                </CredenzaDescription>
              </CredenzaHeader>
              <CredenzaBody className="space-y-4">
                <FormField
                  control={form.control}
                  name="new_consumption"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Unit</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="adjustment_reason"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Adjustment Reason</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="adjustment_notes"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Adjustment Notes</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CredenzaBody>
              <CredenzaFooter>
                <CredenzaClose asChild>
                  <Button variant={"outline"}>Cancel</Button>
                </CredenzaClose>
                <Button disabled={mutation.isPending} type="submit">
                  {mutation.isPending && (
                    <Icons.loader className="mr-2 animate-spin" />
                  )}
                  Submit
                </Button>
              </CredenzaFooter>
            </form>
          </Form>
        </CredenzaContent>
      </Credenza>
    </>
  )
}
