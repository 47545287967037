import { useRef } from "react"
import * as AWS from "aws-sdk"
import * as CryptoJS from "crypto-js"

import { getEnv } from "@/lib/api/env"

const useAwsS3 = () => {
  const s3Ref = useRef<AWS.S3 | null>(null)
  const decryptedEnvRef = useRef<any>(null)

  const initializeConfig = async () => {
    if (s3Ref.current && decryptedEnvRef.current) {
      return { s3: s3Ref.current, decryptedEnv: decryptedEnvRef.current }
    }

    const envResponse = await getEnv()
    const encrypted = envResponse.data
    const key = CryptoJS.enc.Utf8.parse(envResponse.key)

    if (!encrypted) {
      throw new Error("No encrypted data available")
    }

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      mode: CryptoJS.mode.ECB,
    })

    const env = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    decryptedEnvRef.current = env

    AWS.config.update({
      region: env.aws_project_region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: env.aws_cognito_identity_pool_id,
      }),
    })

    const s3Client = new AWS.S3({
      credentials: {
        accessKeyId: env.access_key_id,
        secretAccessKey: env.secret_access_key,
      },
      apiVersion: "2006-03-01",
      params: { Bucket: env.aws_s3_bucket },
    })

    s3Ref.current = s3Client

    return { s3: s3Client, decryptedEnv: env }
  }

  const downloadFileFromS3 = async (key: string) => {
    const { s3, decryptedEnv } = await initializeConfig()

    if (!s3 || !decryptedEnv) return null

    const expirationTime = 5 // Expiration time for the signed URL

    const params: AWS.S3.Types.GetObjectRequest & { Expires: number } = {
      Key: key,
      Bucket: decryptedEnv.aws_s3_bucket,
      Expires: expirationTime,
    }

    return s3.getSignedUrl("getObject", params)
  }

  return { downloadFileFromS3 }
}

export { useAwsS3 }
