import * as React from "react"
import type {
  BillingAdjustmentType,
  PaymentDetailsType,
  RecentPendingPaymentsType,
  WeeklyPaymentsChartType,
} from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import type { DateRange } from "react-day-picker"
import { useReactToPrint } from "react-to-print"

import {
  getBillingAdjustmentList,
  getPaymentHistory,
  getRecentPendingPayments,
  getWeeklyPayments,
} from "@/lib/api/billing"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { BarChartComponent } from "@/components/charts/barchart"
import { DataTable } from "@/components/data-table"
import { DateRangePicker } from "@/components/date-range-picker"
import { Header } from "@/components/header"
import { Icons } from "@/components/icons"
import { billingAdjustmentColumns } from "@/components/table/billing-adjustment-columns"
import { paymentDetailsColumns } from "@/components/table/payment-details-columns"

export const Route = createFileRoute("/_home/reports")({
  component: ReportsPage,
})

function ReportsPage() {
  const [showAllPayments, setShowAllPayments] = React.useState(false)
  const [date, setDate] = React.useState<DateRange>({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date(),
  })

  const {
    data: paymentDetails,
    isError,
    isLoading,
  } = useQuery<PaymentDetailsType[]>({
    queryKey: ["paymentDetails", date],
    queryFn: async () => {
      const from = date.from ?? new Date()
      const to = date.to ?? new Date()
      return await getPaymentHistory(from.toISOString(), to.toISOString())
    },
  })

  const {
    data: billingAdjustment,
    isError: isBillingError,
    isLoading: isBillingLoading,
  } = useQuery<BillingAdjustmentType[]>({
    queryKey: ["billingAdjustment", date],
    queryFn: getBillingAdjustmentList,
  })

  const { data: weeklyPayments } = useQuery<WeeklyPaymentsChartType[]>({
    queryKey: ["weeklyPayments"],
    queryFn: getWeeklyPayments,
  })

  const { data: pendingPayments } = useQuery<RecentPendingPaymentsType[]>({
    queryKey: ["pendingPayments"],
    queryFn: getRecentPendingPayments,
  })

  const contentRef = React.useRef<HTMLDivElement>(null)
  const reactToPrintFn = useReactToPrint({ contentRef })

  const renderPaymentsTable = () => {
    try {
      if (!pendingPayments || pendingPayments.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              No recent pending payments available
            </TableCell>
          </TableRow>
        )
      }

      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Consumer</TableHead>
              <TableHead>Acc No.</TableHead>
              <TableHead>Amount</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pendingPayments
              .slice(0, showAllPayments ? 10 : 5)
              .map((payment: RecentPendingPaymentsType, index) => (
                <TableRow key={index}>
                  <TableCell>{payment.consumer_name}</TableCell>
                  <TableCell>{payment.account_number}</TableCell>
                  <TableCell>₱{payment.amount.toFixed(2)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )
    } catch (error) {
      console.error("Error rendering payments table:", error)
      return null
    }
  }

  return (
    <main className="space-y-8 p-4">
      <Header>Reports</Header>
      <div className="space-y-4">
        <div>
          {/* print:origin-left print:scale-90  */}
          <div ref={contentRef} className="print:p-2">
            <h1 className="pb-2 text-xl font-semibold print:pb-6 print:text-4xl">
              Billing Adjustments
            </h1>
            <DataTable
              columns={billingAdjustmentColumns}
              data={billingAdjustment ?? []}
              search={{
                field: "consumer_name",
                label: "consumer",
              }}
              defaultPage={50}
              isLoading={isBillingLoading}
              isError={isBillingError}
            >
              <div className="flex w-auto justify-end md:w-full">
                <Button
                  onClick={(e) => reactToPrintFn()}
                  variant="outline"
                  className="print:hidden"
                >
                  <Icons.download className="mr-2 h-4 w-4" />
                  Print Report
                </Button>
              </div>
            </DataTable>
          </div>
          <h1 className="pb-2 text-xl font-semibold">Payments</h1>
          <DataTable
            columns={paymentDetailsColumns}
            data={paymentDetails ?? []}
            search={{
              field: "consumer_name",
              label: "consumer",
            }}
            defaultPage={5}
            isLoading={isLoading}
            isError={isError}
          >
            <div className="flex w-auto justify-end md:w-full">
              <DateRangePicker date={date} setDate={setDate} />
            </div>
          </DataTable>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <Card className="bg-background md:col-span-2">
            <CardHeader>
              <CardTitle>Weekly Payments</CardTitle>
            </CardHeader>
            <CardContent className="pl-2 pr-10">
              <BarChartComponent className="h-[300px]" data={weeklyPayments} />
            </CardContent>
          </Card>
          <Card className="bg-background">
            <CardHeader className="flex flex-row items-center justify-between py-3">
              <CardTitle>Recent Pending</CardTitle>
              <Button
                onClick={() => setShowAllPayments(!showAllPayments)}
                variant="outline"
                size="sm"
              >
                {showAllPayments ? "Show Less" : "See All"}
              </Button>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[300px]">
                {renderPaymentsTable()}
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </main>
  )
}
