import type { LoginDataType } from "@/types"
import Cookies from "js-cookie"

import { env } from "@/env"
import { siteConfig } from "@/config/site"

import { getAuthToken, getUserEmail, saveAuth } from "../auth"
import { apiFetch, getBrowserAndOs, getDeviceId, getIpAddress } from "../utils"
import { postActivityLog } from "./log"

export async function authenticate(data: LoginDataType) {
  const res = await apiFetch(
    "login/",
    {
      method: "POST",
      body: JSON.stringify(data),
    },
    true,
  )

  const resData = await res.json()

  if (!res.ok || resData.statusCode !== "200") {
    const errorMessage =
      resData.response || "You have entered invalid credentials"
    throw new Error(errorMessage)
  }

  if (resData.statusCode === "200") {
    saveAuth(resData.user)

    await postActivityLog({
      menu_path: "",
      sub_menu_path: "",
      action: `Login (${data.username})`,
      status_code: res.status,
      api_endpoint: res.url,
      web_url: window.location.href,
    })
  }

  return res
}

export async function deviceLogin(
  app_type: "mobile" | "web",
): Promise<Response> {
  const device_id = getDeviceId()
  const ip_address = await getIpAddress()
  const { browser, os } = getBrowserAndOs()
  const url = `${window.location.origin}/trust-device`
  const app_name = siteConfig.name

  const data = {
    device_id,
    ip_address,
    browser,
    os,
    url,
    app_type,
    app_name,
  }

  const response = await apiFetch("device-login/", {
    method: "POST",
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response
}

export async function markDeviceAsTrusted(deviceId: string) {
  try {
    const response = await apiFetch("mark-device-trusted/", {
      method: "POST",
      body: JSON.stringify({ device_id: deviceId }),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Failed to mark device as trusted:", error)
  }
}

export async function sendMfaOtp() {
  try {
    const email = getUserEmail()

    const response = await apiFetch("generate-otp/", {
      method: "POST",
      body: JSON.stringify({ email, app_name: siteConfig.name }),
    })

    Cookies.set("otp-set-time", new Date().getTime().toString(), {
      expires: 1 / 288,
    })

    if (!response.ok) {
      throw new Error("Failed to send MFA OTP.")
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function verifyOtp(otp: string) {
  try {
    const email = getUserEmail()

    const response = await apiFetch("verify-otp/", {
      method: "POST",
      body: JSON.stringify({ email, otp }),
    })

    if (!response.ok) {
      throw new Error("Invalid OTP")
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function refreshAccessToken(
  refreshToken: string,
): Promise<{ access: string; refresh: string }> {
  try {
    const token = getAuthToken()
    const response = await fetch(`${env.API_URL}token/refresh/`, {
      method: "POST",
      body: JSON.stringify({
        refresh: refreshToken,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const data = await response.json()

    if (data.access && data.refresh) {
      Cookies.set("auth-token", data.access, {
        expires: 1 / 48,
      })
      Cookies.set("refresh-token", data.refresh, {
        expires: 1,
      })
    } else {
      throw new Error("Invalid tokens received from refresh API.")
    }

    return data
  } catch (error) {
    console.error("Failed to refresh access token:", error)
    throw error
  }
}

export async function disableUser(username: string) {
  try {
    const response = await apiFetch("disable-user/", {
      method: "PUT",
      body: JSON.stringify({ username }),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Failed to disable user:", error)
  }
}
