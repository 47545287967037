import * as React from "react"
import { createFileRoute, useNavigate } from "@tanstack/react-router"
import Cookies from "js-cookie"

import { markDeviceAsTrusted, sendMfaOtp } from "@/lib/api/auth"
import { getUserEmail, isMfaEnabled } from "@/lib/auth"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Icons } from "@/components/icons"

export const Route = createFileRoute("/_auth/trust-device")({
  component: Page,
})

function Page() {
  const email = getUserEmail()
  const urlParams = new URLSearchParams(window.location.search)
  const deviceIdFromUrl = urlParams.get("device_id")
  const deviceIdFromLocalStorage = localStorage.getItem(`device_id-${email}`)
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate({ from: "/trust-device" })
  const { toast } = useToast()

  if (deviceIdFromUrl !== deviceIdFromLocalStorage) {
    return (
      <div className="text-2xl font-bold text-gray-600">
        404 - Your Device ID does not match
      </div>
    )
  }

  const handleTrustDevice = async () => {
    if (!deviceIdFromUrl || deviceIdFromUrl !== deviceIdFromLocalStorage) return

    setIsLoading(true)

    try {
      await markDeviceAsTrusted(deviceIdFromUrl)
      localStorage.setItem(`device_trusted-${email}`, "true")

      if (isMfaEnabled()) {
        const mfaAuth = Cookies.get(`mfa-auth-${email}`)
        if (mfaAuth === "true") {
          navigate({ to: "/" })
        } else {
          sendMfaOtp()
          navigate({ to: "/mfa-auth" })
        }
      } else {
        navigate({ to: "/" })
      }
    } catch (error) {
      toast({
        description: "Failed to mark device as trusted!",
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card className="w-full max-w-md bg-white">
      <CardHeader className="text-center">
        <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
          <Icons.smartphone className="h-6 w-6 text-yellow-600" />
        </div>
        <CardTitle className="text-2xl font-bold text-gray-600">
          Trust Device
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-center text-gray-600">
          Do you want to mark this device as trusted?
        </p>
      </CardContent>
      <CardFooter className="flex flex-col space-y-2 text-center text-gray-600">
        <Button disabled={isLoading} onClick={handleTrustDevice}>
          {isLoading && <Icons.loader className="mr-2 animate-spin" />}
          Trust Device
        </Button>
      </CardFooter>
    </Card>
  )
}
