import { createFileRoute, Outlet, redirect } from "@tanstack/react-router"

import { isAuthenticated } from "@/lib/auth"
import { Icons } from "@/components/icons"

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location }) => {
    const authenticated = await isAuthenticated()

    if (authenticated) {
      throw redirect({
        to: "/",
        search: {
          redirect: location.href,
        },
      })
    }
  },
  component: AuthLayout,
})

function AuthLayout() {
  return (
    <div className="flex h-screen flex-col items-center justify-between bg-white">
      <div className="flex flex-col items-center gap-4 px-2 pt-[4rem] md:pt-[13rem]">
        <Icons.himo width="70" height="70" />
        <Outlet />
      </div>
      <div className="flex h-[350px] w-full items-end justify-end bg-[url('/wave.png')] bg-cover bg-no-repeat">
        <div className="p-8 text-xl text-white">by Hiraya Tech</div>
      </div>
    </div>
  )
}
