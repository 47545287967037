import type { UserType } from "@/types"
import Cookies from "js-cookie"

import { env } from "@/env"

import { refreshAccessToken } from "./api/auth"

export function saveAuth(user: UserType) {
  const userInfo = {
    full_name: user.user_info.full_name,
    email: user.user_info.email,
    user_group: user.user_info.user_group,
    username: user.user_info.username,
    user_id: user.user_info.user_id,
    mobile_number: user.user_info.mobile_number,
    address: user.user_info.address,
  }
  Cookies.set("auth-token", user.user_info.token, {
    expires: 1 / 48,
  })
  Cookies.set("refresh-token", user.user_info.refresh_token, {
    expires: 1,
  })
  Cookies.set("user_client_info", JSON.stringify(user.client_info), {
    expires: (1 / 1440) * env.SESSION_DURATION,
  })
  Cookies.set("user_id", user.user_info.user_id, {
    expires: (1 / 1440) * env.SESSION_DURATION,
  })
  Cookies.set("user_info", JSON.stringify(userInfo), {
    expires: (1 / 1440) * env.SESSION_DURATION,
  })
  Cookies.set("is_mfa_enabled", String(user.user_info.is_mfa_enabled), {
    expires: (1 / 1440) * env.SESSION_DURATION,
  })
}

export async function isAuthenticated(): Promise<boolean> {
  let token = getAuthToken()
  const email = getUserEmail()
  const mfaAuth = Cookies.get(`mfa-auth-${email}`)
  const deviceTrusted = localStorage.getItem(`device_trusted-${email}`)

  if (!token) {
    const refreshToken = getRefreshToken()
    if (refreshToken) {
      try {
        const data = await refreshAccessToken(refreshToken)
        token = data.access
      } catch {
        return false
      }
    } else {
      return false
    }
  }

  if (isMfaEnabled()) {
    return !!(token && deviceTrusted && mfaAuth)
  }

  return !!(token && deviceTrusted)
}

export function getAuthToken() {
  const token = Cookies.get("auth-token")
  return token
}

export function getRefreshToken() {
  const token = Cookies.get("refresh-token")
  return token
}

export function getClientId() {
  const client = Cookies.get("user_client_info") || "{}"
  return JSON.parse(client).client_id
}

export function getUserEmail() {
  const userInfo = Cookies.get("user_info") || "{}"
  return JSON.parse(userInfo).email
}

export function isMfaEnabled() {
  return Cookies.get("is_mfa_enabled") === "true"
}

export function logout() {
  Cookies.remove("refresh-token")
  Cookies.remove("auth-token")
  Cookies.remove("user_client_info")
  Cookies.remove("user_id")
  Cookies.remove("user_info")
}
